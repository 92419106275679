<template>
    <v-dialog max-width="600" v-model="dialog">
        <v-card>
            <v-toolbar dark>
                <v-card-title class="text-h5">
                    <span>{{ title }} education level </span>
                    <hr>
                </v-card-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <v-spacer></v-spacer>
                    <v-btn @click="closeDialog" dark icon>
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar-items>
            </v-toolbar>

            <v-card-text>
                <v-container>
                    <v-row class="mt-2">
                        <v-col cols="12" md="12" sm="12">
                            <v-text-field
                                    :error="$v.education_level.title.$error"
                                    dense
                                    label="Title*" outlined
                                    v-model="education_level.title">
                            </v-text-field>
                            <span class="text-danger" v-if="$v.education_level.title.$error">Title is Required</span>
                            <span class="text-danger" v-if="errors.title">**{{errors.title[0]}}</span>
                        </v-col>
                        <v-col cols="12" md="12" sm="12" v-if="currentUser.view_path == 'joinefy'">
                            <v-text-field
                                    :error="$v.education_level.level.$error"
                                    dense
                                    type="number"
                                    label="Level *" outlined
                                    v-model="education_level.level">
                            </v-text-field>
                            <span class="text-danger" v-if="$v.education_level.level.$error">Level is Required</span>
                        </v-col>
                        <v-col cols="12" md="12" sm="12">
                            <ckeditor :config="editorConfig"
                                      v-model="education_level.description"></ckeditor>
                            <span class="text-danger" v-if="errors.description">**{{errors.description[0]}}</span>
                            <span class="text-danger" v-if="$v.education_level.description.$error">Description is Required</span>
                        </v-col>
                      <v-col cols="12" md="6">
                        Status
                        <v-switch
                            v-model="education_level.is_active"
                            :label="education_level.is_active ? 'Active' : 'Inactive'"
                        ></v-switch>
                      </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                        @click="closeDialog"
                        color="mr-2" text dark
                        medium>
                    Cancel
                </v-btn>
                <v-btn
                    v-if="checkIsAccessible('education-level', 'create') || checkIsAccessible('education-level', 'edit')"
                        :loading="loading"
                        @click="createOrUpdate()"
                        class="btn btn-primary"
                        medium
                >
                    {{edit ? "Update" : "Save"}}
                </v-btn>

            </v-card-actions>
        </v-card>
    </v-dialog>

</template>
<script>
import EducationLevelService from "@/services/academic/education-level/EducationLevelService";
import {required} from "vuelidate/lib/validators";

const educationLevelService = new EducationLevelService();
export default {
    validations: {
        education_level: {
            title: {required},
            description: {required},
        },
    },
    data() {
        return {
            title: 'Add',
            dialog: false,
            edit: false,
            loading: false,
            states: [],
            errors: [],
            education_level_category: [],
            editorConfig: {
              versionCheck: false,
              toolbar: [['Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList']]
            },
            education_level: {
                title: '',
                description: '',
                is_active: 1,
            },
        }
    },
    methods: {
        closeDialog() {
            this.dialog = false;
            this.errors = [];
            this.resetForm();
            this.$v.$reset()
        },
        openDialog() {
            this.dialog = true;
        },
        resetForm() {
            this.education_level = {
                title: '',
                description: '',
                is_active: 1,
                level_category_id: '',
            };
        },
        createForm() {
            this.resetForm();
            this.openDialog();
            this.edit = false;
            this.title = "Add";
        },
        editForm(item) {
            this.openDialog();
            this.edit = true;
            this.education_level = item;
            this.title = "Edit";
        },
        createOrUpdate() {
            this.$v.$touch()
            if (this.$v.$error) {
                setTimeout(() => {
                    this.$v.$reset()
                }, 3000);
            } else {
                if (!this.edit) this._create();
                else this.__update();
            }
        },
        _create: function () {
            this.loading = true;
            educationLevelService
                .create(this.education_level)
                .then(response => {
                    this.$snotify.success("Education Level created successfully");
                    this.closeDialog();
                    this.resetForm();
                    this.errors = [];
                    this.loading = false;
                    this.$emit('refresh');
                })
                .catch(error => {
                    this.errors = error.errors;
                })
        },
        __update: function () {
            this.loading = true;
            educationLevelService
                .update(this.education_level.id, this.education_level)
                .then(response => {
                    this.$snotify.success("Education Level updated successfully");
                    this.closeDialog();
                    this.resetForm();
                    this.errors = [];
                    this.loading = false;
                    this.$emit('refresh');
                })
                .catch(error => {
                    this.errors = error.errors;
                })
        },

        __detail() {
            if (this.exam_level_id != null || this.exam_level_id != undefined) {
                educationLevelService
                    .show(this.exam_level_id)
                    .then(response => {
                        this.state = response.data.state;

                    })
            }
        },
    },
    mounted() {
        this.__detail();
    },
    computed: {
        exam_level_id() {
            return this.$route.params.exam_level_id;
        },
        titleText() {
            if (this.exam_level_id != null || this.exam_level_id != undefined)
                return this.title = "Edit";
            else
                return this.title = "Add new"
        }
    }
}
</script>
