<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Education Level </h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard</router-link>
                  \ Education Level
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn v-if="checkIsAccessible('education-level', 'create')" @click="__create()" class="mt-4 btn btn-primary" style="color: #fff">
                    <v-icon elevation="2" outlined small>fas fa-plus</v-icon>&nbsp;
                    Add New
                  </v-btn>
<!--                  <v-btn-->
<!--                      @click="category()"-->
<!--                      class="mt-4 ml-3 btn btn-standard"-->
<!--                  >-->
<!--                    <v-icon small elevation="2" outlined>fas fa-university</v-icon>&nbsp;-->
<!--                    Education Level Category-->
<!--                  </v-btn>-->
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">
                <v-col cols="12" md="6" sm="12">
                  <v-text-field clearable dense
                                label="Lookup by Name, Short Name" outlined v-model="search.info"
                                v-on:keyup.enter="__search"></v-text-field>
                </v-col>
                <v-col class="d-flex" cols="12" sm="12" md="4">
                  <v-select
                      label="Status"
                      v-model="search.is_active"
                      v-on:keyup.enter="__search"
                      @input="search.is_active = $event !== null ? $event : ''"
                      item-text="name"
                      item-value="title"
                      :items="status"
                      clearable
                      outlined
                      dense
                  ></v-select>
                </v-col>
                <v-col cols="12" md="2" sm="12">
                  <v-btn :loading="isLoading" @click="__search"
                  class="mt-1 btn btn-primary text-white text-left" 
                  >
                    <v-icon elevation="2" outlined small>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>
            </div>

            <div class="table-responsive" style="table-layout: fixed">
              <v-skeleton-loader
                  type="table-thead"
                  v-if="isLoading">
              </v-skeleton-loader>

              <v-skeleton-loader
                  type="table-row-divider@25"
                  v-if="isLoading">
              </v-skeleton-loader>
              <table class="table">
                <thead>

                <tr class="px-3">
                  <th :class="sortedClass('name')" @click="sortBy('name')" class="px-3">
                    <strong>Name</strong></th>
                  <th class="px-3" v-if="currentUser.view_path == 'joinefy'"><strong>Level</strong></th>
                  <th><strong>Status</strong></th>
                  <th class="pr-3 text-center"><strong>ACTION</strong></th>
                </tr>

                </thead>

                <draggable @change="sortData" v-model="education_levels"
                           v-if=" education_levels && education_levels.length>0 && !isLoading"   class="text-left" tag="tbody"
                           >
                    <tr v-for="(item, index) in education_levels" :key="index">
                      <td class="px-2">
<!--                        <i v-if="search.education_category_level_id" class="cursor-drag fas fa-sort handle pr-2"></i>-->
                        <a @click="__edit(item)"
                           class="text-primary font-weight-bolder text-hover-primary mb-1 font-size-lg">
                          {{ item.title }}
                        </a>
                      </td>
                      <td class="px-2" v-if="currentUser.view_path == 'joinefy'">
                        <span class="badge badge-info"
                        >{{ item.level }}</span>
                      </td>

                      <td class="px-2">
                           <span class="badge badge-success text-lg`"
                                 v-bind:class="{ 'badge-success': item.is_active, 'badge-danger': !item.is_active }"
                           >{{ item.is_active ? 'Active' : 'Inactive' }}</span>
                      </td>

                      <td class="pr-0 text-center">
                        <template>
                          <b-dropdown
                              no-caret
                              no-flip
                              right
                              size="sm"
                              toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                              variant="link"
                          >
                            <template v-slot:button-content>
                              <i class="ki ki-bold-more-hor"></i>
                            </template>
                            <!--begin::Navigation-->
                            <div class="navi navi-hover min-w-md-250px">
                              <b-dropdown-text class="navi-item" tag="div" v-if="checkIsAccessible('education-level', 'edit')">
                                <a @click="__edit(item)" class="navi-link">
                                                                <span class="navi-icon">
                                                                    <v-icon color="orange darken-2">fas fa-edit</v-icon>
                                                                </span>
                                  <span class="navi-text">Edit  </span>
                                </a>
                              </b-dropdown-text>
                              <b-dropdown-text class="navi-item" tag="div">
                                <a @click="__delete(item.id)" class="navi-link" v-if="checkIsAccessible('education-level', 'delete')">
                                                                <span class="navi-icon">
                                                                    <v-icon color="red">fas fa-trash</v-icon>
                                                                </span>
                                  <span class="navi-text">Delete</span>
                                </a>
                              </b-dropdown-text>
                            </div>
                            <!--end::Navigation-->
                          </b-dropdown>
                        </template>
                      </td>
                    </tr>
                    <tr v-if="education_levels.length == 0">
                      <td class="text-center" colspan="4">
                        <strong>No items added</strong>
                      </td>
                    </tr>

                </draggable>

              </table>
              <b-pagination
                  :disabled="isLoading"
                  :per-page="perPage"
                  :total-rows="total"
                  @input="_getAll"
                  class="pull-right mt-7"
                  first-number
                  last-number
                  v-if="education_levels.length > 0" v-model="page"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
      <create-or-update @refresh="_getAll" ref="create-or-update"></create-or-update>
    </div>
  </v-app>
</template>
<script>
import draggable from 'vuedraggable'
import EducationLevelService from "@/services/academic/education-level/EducationLevelService";
import CreateOrUpdate from "./CreateOrUpdate";

const educationLevelService = new EducationLevelService();

export default {
  components: {
    CreateOrUpdate,
    draggable
  },
  data() {
    return {
      isLoading: false,
      search: {
        info: '',
        is_active: '',
      },
      education_levels: [],
      total: null,
      perPage: null,
      page: null,
      sort: {
        key: '',
        isAsc: false
      },
      status: [
        {name: 'Active', value: 1},
        {name: 'Inactive', value: 0},
      ],
      education_category_levels: []

    }
  },
  mounted() {
    this._getAll();
  },
  methods: {
    _getAll() {
      this.isLoading = true;
      educationLevelService
          .paginate(this.search, this.page)
          .then(response => {
            this.education_levels = response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
            this.isLoading = false;
          })
          .catch((err) => {
            this.education_levels = [];
          }).finally(() => {
        this.isLoading = false;
      });
    },
    __search() {
      this._getAll();
    },
    sortedClass(key) {
      return this.sort.key === key ? `sorted ${this.sort.isAsc ? 'asc' : 'desc'}` : '';
    },
    sortBy(key) {
      this.sort.isAsc = this.sort.key === key ? !this.sort.isAsc : false;
      this.sort.key = key;
    },
    __create() {
      this.$refs['create-or-update'].createForm();
    },
    __edit(item) {
      this.$refs['create-or-update'].editForm(item);
    },
    __delete(item) {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            educationLevelService
                .delete(item)
                .then((response) => {
                  this._getAll();
                  this.$snotify.success("Deleted");
                })
                .catch((err) => {
                  this.isLoading = false;
                  this.$snotify.error("Oops something went wrong");
                });
          }
        },
      });
    },

    category() {
      this.$router.push({
        name: "education-level-category",
      });
    },
    sortData(){
      this.isLoading =true
      educationLevelService.sort(this.education_levels).then(response=>{
        this.$snotify.success("Education level has been sorted")
      }).catch(error=>{

      }).finally(this.isLoading =false)
    }
  },

}
</script>
<style lang="sass">
table
  th.sorted
    &.asc::after
      display: inline-block
      content: '▼'
      color: red

    &.desc::after
      display: inline-block
      content: '▲'
      color: red

</style>